<template>
  <div>
    <card-advance/>
  </div>
</template>

<script>
import { BCard, BCardText, BLink } from 'bootstrap-vue'
import CardAdvance from "@/views/card/card-advance/CardAdvance";

export default {
  components: {
    CardAdvance,
    BCard,
    BCardText,
    BLink,
  },
}
</script>

<style>

</style>
